import {
  useMediaQuery,
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  DialogActions,
} from '@mui/material';
import React, { useState } from 'react';
import { Theme, createStyles, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Order from '../../models/order';

interface PropsPaiement {
  open: boolean;
  onClose: any;
  onConfirm: any;
  onOpen: any;
  totalAmount: string;
  orders: Order[];
}

export default function Paiement(props: PropsPaiement) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const [disabled, setDisabled] = useState(true);
  const [amount, setAmount] = useState(' ');
  // const [total, setTotal] = useState("55.35");
  const [rendu, setRendu] = useState('');

  const onChangeAmount = (ant: string) => {
    const amnt = amount + ant;
    const rendu = parseFloat(amnt) - parseFloat(props.totalAmount);
    setAmount(amnt);
    setRendu(rendu.toFixed(2).toString());
    props.orders.forEach((ele) => {
      ele.paymentMethod = 'CASH';
    });
    if (rendu > 0) setDisabled(false);
  };

  const handleClear = () => {
    setAmount('');
    setRendu('');
    setDisabled(true);
  };

  const handleCashPaiment = () => {
    // setAmount(total);
    setRendu('');
    props.orders.forEach((ele) => {
      ele.paymentMethod = 'CASH';
    });
    setDisabled(false);
  };

  const handleCreditPaiment = () => {
    setAmount(props.totalAmount);
    setRendu('');
    props.orders.forEach((ele) => {
      ele.paymentMethod = 'CREDIT';
    });
    setDisabled(false);
  };

  const handleDebitPaiment = () => {
    setAmount(props.totalAmount);
    setRendu('');
    props.orders.forEach((ele) => {
      ele.paymentMethod = 'DEBIT';
    });
    setDisabled(false);
  };
  const [paiementMethod, setPaiementMethod] = useState({
    debit: false,
    cash: false,
    credit: false,
  });

  const [paiementAmount, setPaiementAmount] = useState({
    debit: '0',
    cash: '0',
    credit: '0',
  });

  const handleAmoutChange = (name: string) => {
    switch (name) {
      case 'debit':
        if (!paiementMethod.debit)
          setPaiementAmount((paiementAmount) => ({
            ...paiementAmount,
            [name]: props.totalAmount,
          }));
        else
          setPaiementAmount((paiementAmount) => ({
            ...paiementAmount,
            ['debit']: '0',
          }));
        break;
      case 'credit':
        if (!paiementMethod.credit)
          setPaiementAmount((paiementAmount) => ({
            ...paiementAmount,
            [name]: props.totalAmount,
          }));
        else
          setPaiementAmount((paiementAmount) => ({
            ...paiementAmount,
            ['credit']: '0',
          }));
        break;
      case 'cash':
        if (!paiementMethod.cash)
          setPaiementAmount((paiementAmount) => ({
            ...paiementAmount,
            [name]: props.totalAmount,
          }));
        else
          setPaiementAmount((paiementAmount) => ({
            ...paiementAmount,
            ['cash']: '0',
          }));
        break;
      default:
        break;
    }
  };

  const handleOnchangePaiement = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaiementMethod((paiementMethod) => ({
      ...paiementMethod,
      [event.currentTarget.name]: event.currentTarget.checked,
    }));

    switch (event.currentTarget.name) {
      case 'debit':
        handleAmoutChange('debit');

        break;
      case 'credit':
        handleAmoutChange('credit');
        break;
      case 'cash':
        handleAmoutChange('cash');
        break;
      default:
        break;
    }
  };

  return (
    <Container maxWidth="md">
      <Button variant="outlined" color="primary" onClick={props.onOpen}>
        Paiement
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle>Paiement</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <Button
                  style={{ height: 80, marginRight: 20 }}
                  variant="contained"
                  onClick={() => onChangeAmount('1')}
                  color="primary"
                >
                  1
                </Button>
                <Button
                  style={{ height: 80, marginRight: 20 }}
                  variant="contained"
                  onClick={() => onChangeAmount('2')}
                  color="primary"
                >
                  2
                </Button>
                <Button
                  style={{ height: 80, marginRight: 20 }}
                  variant="contained"
                  onClick={() => onChangeAmount('3')}
                  color="primary"
                >
                  3
                </Button>
              </Grid>

              <Grid item md={4}>
                <span>Total: {props.totalAmount}</span>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item md={6}>
                <Button
                  style={{ height: 80, marginRight: 20 }}
                  variant="contained"
                  onClick={() => onChangeAmount('4')}
                  color="primary"
                >
                  4
                </Button>
                <Button
                  style={{ height: 80, marginRight: 20 }}
                  variant="contained"
                  onClick={() => onChangeAmount('5')}
                  color="primary"
                >
                  5
                </Button>
                <Button
                  style={{ height: 80, marginRight: 20 }}
                  variant="contained"
                  onClick={() => onChangeAmount('6')}
                  color="primary"
                >
                  6
                </Button>
              </Grid>

              <Grid item md={4}>
                <span>Amount: {amount}</span>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item md={6}>
                <Button
                  style={{ height: 80, marginRight: 20 }}
                  variant="contained"
                  onClick={() => onChangeAmount('7')}
                  color="primary"
                >
                  7
                </Button>
                <Button
                  style={{ height: 80, marginRight: 20 }}
                  variant="contained"
                  onClick={() => onChangeAmount('8')}
                  color="primary"
                >
                  8
                </Button>
                <Button
                  style={{ height: 80, marginRight: 20 }}
                  variant="contained"
                  onClick={() => onChangeAmount('9')}
                  color="primary"
                >
                  9
                </Button>
              </Grid>

              <Grid item md={4}>
                <span>Rendu: {rendu}</span>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item md={3}>
                <Button
                  style={{ height: 80, marginRight: 20 }}
                  variant="contained"
                  onClick={() => onChangeAmount('0')}
                  color="primary"
                >
                  0
                </Button>
              </Grid>

              <Grid item md={9}>
                <Button
                  style={{ height: 80, marginRight: 20 }}
                  onClick={handleCashPaiment}
                  variant="contained"
                  color="inherit"
                >
                  Cash
                </Button>
                <Button
                  style={{ height: 80, marginRight: 20 }}
                  onClick={handleCreditPaiment}
                  variant="contained"
                  color="inherit"
                >
                  Credit
                </Button>
                <Button
                  style={{ height: 80, marginRight: 20 }}
                  onClick={handleDebitPaiment}
                  variant="contained"
                  color="inherit"
                >
                  Debit
                </Button>
                <Button
                  variant="contained"
                  onClick={handleClear}
                  style={{ height: 80, marginRight: 20 }}
                  color="secondary"
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.onClose} color="secondary">
            Cancel
          </Button>

          <Button
            onClick={() => props.onConfirm(props.orders)}
            color="primary"
            autoFocus
            disabled={disabled}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
