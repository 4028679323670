import React, { useEffect } from "react";
import JsBarcode from "jsbarcode";

interface BarcodeProps {
  name: string;
}
export default function BarcodeRenderer(props: BarcodeProps) {
  useEffect(() => {
    console.log("IN renderer: ", props);
    JsBarcode(props.name).init();
  }, []);
  return null;
}
