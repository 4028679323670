import Category from "./category";
import Marque from "./marque";
import Fournisseur from "./fournisseur";
import ProductIdent from "./productIdent";
import ProductPricing from "./productPricing";

// type InnerObj = {
//   [key: string]: string | boolean;
//   isDeduction: boolean;
// };
export default class Products {
  [key: string]:
    | string
    | undefined
    | number
    | Fournisseur
    | boolean
    | ProductIdent
    | ProductPricing;
  identifications: ProductIdent = new ProductIdent();
  productPricing: ProductPricing = new ProductPricing();
  quantities: string = '';
  category: Category = new Category();
  marque: Marque = new Marque();
  fournisseur: Fournisseur = new Fournisseur();
  // isDeduction: boolean = true;
  // categorie: string = "";

  price: string = '';
  marge: number = 0;
  majoration: number = 0;
  id: string = '';
  name: string = '';
}
