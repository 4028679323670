import axios from 'axios';
import { useState, ChangeEvent, useEffect } from 'react';
// import axios from "axios";

import Employee from '../../../models/employee';

const useEmployee = () => {
  const [employee, setEmployee] = useState(new Employee());
  const [employees, setEmployees] = useState(new Array<Employee>());

  const handleEmployeeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmployee((employee) => ({
      ...employee,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    const fetchEmployee = async () => {
      var result = await axios.get(`https://www.posb.recy-globe.com/employee`);
      setEmployees(result.data);
    };
    fetchEmployee();
    return () => {
      //   cleanup
    };
  }, []);
  const handleSubmit = async () => {
    var result = await axios.post(
      `https://www.posb.recy-globe.com/employee`,
      employee
    );
  };

  return { employee, employees, handleEmployeeChange, handleSubmit };
};

export default useEmployee;
