import React, { useState, ReactElement } from "react";
import clsx from "clsx";
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import useStyles from './useStyles';
import ProductsPage from '../../views/pages/products/ProductsPage';
import Sales from '../../views/pages/Sales/Sales';
import Employees from '../../views/pages/Employee/Employees';
import BarcodeGeneration from '../../views/pages/BarcodeGenetation/BarcodeGeneration';
import Home from '../../views/pages/Home/Home';
import Dashbord from '../../views/pages/Dashboard/Dashbord';
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

class DrawerMenuItem {
  name: string | undefined;
  content: (() => ReactElement) | undefined;
}

export default function MuiDrawer() {
  const classes = useStyles() as any;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = useState(new DrawerMenuItem().content);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const changeContent = (selectedMenu: any) => {
    switch (selectedMenu) {
      case 'Home':
        return setContent(<Home />);
        break;
      case 'Dashboard':
        return setContent(<Dashbord />);
        break;
      case 'Stock':
        return setContent(<ProductsPage />);
        break;

      case 'Sales':
        return setContent(<Sales />);
        break;
      case 'Employees':
        return setContent(<Employees />);
        break;

      case 'Barcode Generation':
        return setContent(<BarcodeGeneration />);
        break;
      default:
        break;
    }
    console.log(selectedMenu);
    if (selectedMenu == 'Stock') return setContent(<ProductsPage />);
    else return setContent(<div>In Progress</div>);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Lorraine POS
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {[
            'Home',
            'Stock',
            'Sales',
            'Dashboard',
            'Employees',
            'Barcode Generation',
          ].map((text, index) => (
            <ListItem button key={text} onClick={() => changeContent(text)}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {content}
      </main>
    </div>
  );
}
