import React, { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import JsBarcode from "jsbarcode";
import * as canvas from "canvas";
import Canvg, { presets } from "canvg";
import Barcode from "./Barcode";
import BarcodeRenderer from './BarcodeRenderer';
import MuiTextField from "../../../components/helper/MuiTextField";
import jsPDF from "jspdf";
const preset = presets.node({
  DOMParser,
  canvas,
  fetch,
});

const styles = {
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between'

    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: "10px",
  },
  root1: {
    fontFamily: "Roboto",
    fontSize: "x-large",
    fontWeight: "bold",
    textTransform: "uppercase",
  } as React.CSSProperties,

  spanBarcode: {},

  row: {
    display: "flex",
    flexDirection: "row",
  },
};

export default function BarcodeGeneration() {
  const [codeForGeneration, setCodeForGeneration] = useState("1234567890128");
  const [nameBareCode, setNameBareCode] = useState("");
  const [priceBarCode, setPriceBarCode] = useState("");
  const handleCodeChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    await setCodeForGeneration(event.target.value);
    JsBarcode(".barcode").init();
    console.log("codeForGeneration: ", codeForGeneration);
  };

  const Test = () => {
    return (
      <div id="codebaretest">
        {/* <Grid container spacing={2}>
            <Grid item xs={12} md={4} sm={4}> */}
        <input
          name={"codeForGeneration"}
          value={codeForGeneration}
          // error=""
          type="text"
          onChange={handleCodeChange}
          autoComplete="Code"
          // label="Code"
          // margin="normal"
          // variant="filled"
        />
        {/* </Grid>
    
            <Grid item xs={12} md={3} sm={3}> */}
        <input
          name={"nameBareCode"}
          value={nameBareCode}
          // error=""
          type="text"
          onChange={handleNameBareCodeChange}
          autoComplete=""
          // label="Barcode name"
          // margin="normal"
          // variant="filled"
        />
        {/* </Grid>
    
            <Grid item xs={12} md={2} sm={2}> */}
        <input
          name={"priceBarCode"}
          value={priceBarCode}
          // error=""
          type="text"
          onChange={handlePriceBarCodeChange}
          autoComplete=""
          // label="Barcode price"
          // margin="normal"
          // variant="filled"
        />
        {/* </Grid>
          </Grid> */}
        <span style={styles.root1}>{nameBareCode}</span>
        <div style={styles.root}>
          {/* {values.map((it) => (
              <Barcode value={it} />
            ))} */}
          <Barcode
            name={nameBareCode}
            price={priceBarCode}
            value={codeForGeneration}
          />
          <BarcodeRenderer name=".barcode" />
        </div>
        <span style={styles.root1}>{priceBarCode} $</span>
      </div>
    );
  };

  const handleNameBareCodeChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNameBareCode(event.target.value);
  };
  const handlePrintBarcode = async () => {
    const test1 = await renderToString(<Test />);
    var svg = document.getElementById("codebaretest")?.outerHTML || "";
    var svg1 = document.getElementById("svgId");
    console.log("svg: ", svg1);
    console.log("test1: ", test1);
    const pdf = new jsPDF();
    // pdf.html(svg);

    // (async (output, input) => {
    //   const svg = document.getElementById("svgId")?.outerHTML || "";
    //   const canv = preset.createCanvas(800, 600);
    //   const ctx = canv.getContext("2d");
    //   const v = Canvg.fromString(ctx, svg, canv);

    //   // Render only first frame, ignoring animations.
    //   await v.render();

    //   const png = canv.toBuffer();
    //   pdf.addImage(png, 10, 10, 120, 80);
    //   // await fs.writeFile(output, png);
    // })(process.argv.pop(), process.argv.pop());
    if (svg1) {
      const svgData = new XMLSerializer().serializeToString(svg1);
      pdf.addSvgAsImage(svgData, 20, 20, 120, 80, "SLOW");
      pdf.autoPrint();
      pdf.output("dataurlnewwindow");
    }

    // // pdf.text(test, 10, 10);
    // pdf.html(svg).then(() => {
    //   pdf.autoPrint();
    //   pdf.output("dataurlnewwindow");
    // });
  };
  const handlePriceBarCodeChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPriceBarCode(event.target.value);
  };

  return (
    <div>
      <Test />
      <button onClick={handlePrintBarcode}>Print Barcode</button>
    </div>
  );
}
