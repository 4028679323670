import React from "react";
// import { Container, Grid, Button } from "@material-ui/core";

import Products from "../../models/Products";

import ProductIdentForm from "./ProductIdentForm";
import ProductPricingForm from "./ProductPricingForm";
import OrganiserForm from "./OrganiserForm";
import Category from "../../models/category";
import ProductIdent from "../../models/productIdent";
import ProductPricing from "../../models/productPricing";
import Marque from "../../models/marque";
import { Container, Grid, Button } from "@mui/material";

interface PropsProducts {
  datas: Products;
  productIdent: ProductIdent;
  productPricing: ProductPricing;
  category: Category;
  categories: Category[];
  marque: Marque;
  marques: Marque[];
  onChange: any;
  onSave: any;
}

export default function ProductForm(props: PropsProducts) {
  const changesEvents = {
    onMarqueChange: props.onChange.onMarqueChange,
    onCategoryChange: props.onChange.onCategoryChange,
  };
  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item md={6}>
          <ProductIdentForm
            productIdent={props.productIdent}
            onChange={props.onChange.onProductIdentChange}
          />
        </Grid>

        <Grid item md={6}>
          <ProductPricingForm
            productPricing={props.productPricing}
            onChange={props.onChange.onProductPricingChange}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <OrganiserForm
            categories={props.categories}
            onChange={changesEvents}
            onSave={props.onSave}
            category={props.category}
            marque={props.marque}
            marques={props.marques}
          />
        </Grid>

        <Grid item md={2}>
          <Button
            variant="contained"
            onClick={props.onSave.onSaveProduct}
            color="primary"
          >
            Save Product
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
