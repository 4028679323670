export default class Handler {
  /**
   *
   */
  constructor(
    method: any,
    label: string,
    variant: string,
    color: string,
    spacing: number
  ) {
    this.method = method;
    this.label = label;
    this.variant = variant;
    this.color = color;
    this.spacing = spacing;
  }

  method: any;
  label = "Update";
  variant = "contained";
  color = "primary";
  spacing = 2;
}
