import React from "react";
// import { Paper, FormControlLabel, Grid } from "@material-ui/core";
import MuiTextField from "../helper/MuiTextField";
import { propertyOf } from "../utils";
import ProductIdent from "../../models/productIdent";
import { Paper } from "@mui/material";

interface PropsProductIdent {
  productIdent: ProductIdent;
  onChange: any;
}

export default function ProductIdentForm(props: PropsProductIdent) {
  return (
    // <Grid item md={12}>
    <Paper variant="outlined">
      Product Identification
      <MuiTextField
        name={propertyOf<ProductIdent>("name").toString()}
        value={props.productIdent?.name}
        error=""
        type="text"
        onChange={props.onChange}
        autoComplete="Name"
        label="Name"
        margin="normal"
        variant="filled"
      />
      <MuiTextField
        name={propertyOf<ProductIdent>("systemIdent").toString()}
        value={props.productIdent.systemIdent}
        error=""
        type="text"
        onChange={props.onChange}
        autoComplete="System Idententification"
        label="Serial number"
        margin="normal"
        variant="filled"
      />
      <MuiTextField
        name={propertyOf<ProductIdent>("manufacturerIdent").toString()}
        value={props.productIdent?.manufacturerIdent}
        error=""
        type="text"
        onChange={props.onChange}
        autoComplete="Manufacturer Identification"
        label="Custom SKU"
        margin="normal"
        variant="filled"
      />
      <MuiTextField
        name={propertyOf<ProductIdent>("quantities").toString()}
        value={props.productIdent?.quantities}
        error=""
        type="number"
        onChange={props.onChange}
        autoComplete="Quantity"
        label="Quantity"
        margin="normal"
        variant="filled"
      />
      <MuiTextField
        name={propertyOf<ProductIdent>("id").toString()}
        value={props.productIdent.id}
        error=""
        type="text"
        onChange={props.onChange}
        autoComplete="Id"
        label="Id"
        margin="normal"
        variant="filled"
      />
    </Paper>
    // </Grid>
  );
}
