import MuiTextField from '../helper/MuiTextField';
import Autocomplete from '@mui/material/Autocomplete';
import { propertyOf } from '../utils';
import Category from '../../models/category';
import { TextField } from '@mui/material';

interface PropsCategory {
  categories: Category[];
  category: Category;
  onChange: any;
  onSave: any;
}

export default function CategoryForm(props: PropsCategory) {
  console.log('change: ', props.onChange);
  // const [categories, setCategories] = useState(new Array<Category>());
  // useEffect(() => {
  //   // get categories
  //   const fetchCategorie = async () => {
  //     var result = await Axios.get("http://localhost:5000/category");
  //     setCategories(result.data);
  //     console.log(result);
  //   };
  //   fetchCategorie();
  //   return () => {
  //     // cleanup
  //   };
  // }, []);

  const handleTagsCahnge =
    () => (event: object, value: any | any[], reason: string) => {
      console.log('category form: ', value);
      // setTags(value);
    };
  return (
    <Autocomplete
      onChange={props.onChange}
      // getOptionSelected={(option, value) => option.id === value.id}
      // onChange={handleTagsCahnge}
      value={props.category}
      options={props.categories}
      getOptionLabel={(option) => option.name}
      style={{ width: 'inherit' }}
      renderInput={(params) => (
        <TextField
          {...params}
          onKeyPress={props.onChange}
          onChange={props.onChange}
          label="Select or create new category (press enter)"
          variant="outlined"
        />
      )}
    />
    // <Container maxWidth="xl">
    /* <Grid container spacing={3} md={12}> */
    /* <Grid item xs={12} sm={6} md={6}> */

    /* <Autocomplete
        onChange={handleTagsCahnge}
        options={props.categories}
        // options={props.datas}
        getOptionLabel={(option) => option.name}
        style={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select categor / Create new and press enter"
            variant="outlined"
          />
        )}
      /> */
    /* </Grid> */

    /* <Grid item md={4}>
          <MuiTextField
            name={propertyOf<Category>("name")}
            value={props.category?.name}
            error=""
            type="text"
            onChange={props.onChange}
            autoComplete="name"
            label="New Categorie"
            margin="normal"
            variant="filled"
          />
        </Grid> */

    /* <Grid item md={4}>
          <Button variant="contained" onClick={props.onSave} color="primary">
            Add new category
          </Button>
        </Grid> */
    /* </Grid> */
    // </Container>
  );
}
