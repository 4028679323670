import Marque from '../../models/marque';
import { Autocomplete, TextField } from '@mui/material';

interface PropsCategory {
  marques: Marque[];
  onChange: any;
  onSave: any;
  marque: Marque;
}

export default function MarqueForm(props: PropsCategory) {
  return (
    <Autocomplete
      options={props.marques}
      // options={props.datas}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      // getOptionSelected={(option, value) => option.id === value.id}
      value={props.marque}
      onChange={props.onChange}
      getOptionLabel={(option) => option.name}
      style={{ width: 'inherit' }}
      renderInput={(params) => (
        <TextField
          name={'marque'}
          {...params}
          // onChange={props.onChange}
          onKeyPress={props.onChange}
          label="Select or create new marque (press enter)"
          variant="outlined"
        />
      )}
    />
  );
}
