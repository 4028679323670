import React, { useState, useEffect } from "react";
// import { Grid, Container } from "@material-ui/core";
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import ProductForm from "../../../components/product/ProductForm";
import RegularTable from "../../../components/helper/RegularTable";
import useProducts from "./useProduct";
import { Container, Grid } from "@mui/material";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       width: "100%",
//     },
//     paper: {
//       width: "100%",
//       marginBottom: theme.spacing(2),
//     },
//     table: {
//       minWidth: 750,
//     },
//     visuallyHidden: {
//       border: 0,
//       clip: "rect(0 0 0 0)",
//       height: 1,
//       margin: -1,
//       overflow: "hidden",
//       padding: 0,
//       position: "absolute",
//       top: 20,
//       width: 1,
//     },
//   })
// );

export default function ProductsPage() {
  const [
    products,
    currentProduct,
    category,
    marque,
    categories,
    marques,
    productIdent,
    productPricing,
    columns,
    // handleUpdate,
    // handleDelete,
    // handleView,
    handlers,
    handleClick,
    // handleProductIdentChange,
    // handleProductPricingChange,
    // handleCategoryChange,
    // handleMarqueChange,
    changesEvents,
    // handleSave,
    // handleSaveCategory,
    // handleSaveMarque,
    saveEvents,
  ] = useProducts();

  return (
    <Container maxWidth="lg">
      <Grid container></Grid>

      <Grid item md={12}>
        <ProductForm
          onSave={saveEvents}
          onChange={changesEvents}
          datas={currentProduct}
          productIdent={productIdent}
          productPricing={productPricing}
          category={category}
          marque={marque}
          marques={marques}
          categories={categories}
        />
      </Grid>

      <Grid item md={12}>
        Products
        <RegularTable
          onChange={handleClick}
          onClick={handleClick}
          datas={products}
          handlers={handlers}
          columns={columns}
        />
      </Grid>
    </Container>
  );
}
