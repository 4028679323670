import { Container, Grid, Button } from '@mui/material';
// import { Container, Grid, Button } from "@material-ui/core";
import MuiTextField from '../../../components/helper/MuiTextField';
import { propertyOf } from '../../../components/utils';
import Employee from '../../../models/employee';

interface EmployeeProps {
  employee: Employee;
  onChange: any;
  handleSubmit: any;
}
export default function EmployeeForm(props: EmployeeProps) {
  return (
    <Container maxWidth={'lg'}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} sm={6}>
          <MuiTextField
            name={propertyOf<Employee>('name').toString()}
            value={props.employee.name}
            error=""
            type="text"
            onChange={props.onChange}
            autoComplete="Name"
            label="Name"
            margin="normal"
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} md={6} sm={6}>
          <MuiTextField
            name={propertyOf<Employee>('code').toString()}
            value={props.employee.code}
            error=""
            type="text"
            onChange={props.onChange}
            autoComplete="Code"
            label="Code"
            margin="normal"
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} md={6} sm={6}>
          <MuiTextField
            name={propertyOf<Employee>('password').toString()}
            value={props.employee.password}
            error=""
            type="password"
            onChange={props.onChange}
            // autoComplete="Password"
            label="Password"
            margin="normal"
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} md={6} sm={6}>
          <Button
            variant="outlined"
            color="primary"
            onClick={props.handleSubmit}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
