import axios from 'axios';
import { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

const rand = () => Math.floor(Math.random() * 255);

const genData = (datas: any) => ({
  labels: [
    new Date(new Date().getTime() + -6 * 24 * 60 * 60 * 1000).getDate(),
    new Date(new Date().getTime() + -5 * 24 * 60 * 60 * 1000).getDate(),
    new Date(new Date().getTime() + -4 * 24 * 60 * 60 * 1000).getDate(),
    new Date(new Date().getTime() + -3 * 24 * 60 * 60 * 1000).getDate(),
    new Date(new Date().getTime() + -2 * 24 * 60 * 60 * 1000).getDate(),
    new Date(new Date().getTime() + -1 * 24 * 60 * 60 * 1000).getDate(),
    new Date().getDate(),
  ],
  datasets: [
    {
      type: 'line',
      label: 'Orders',
      borderColor: `rgb(${rand()}, ${rand()}, ${rand()})`,
      borderWidth: 2,
      fill: false,
      data: [
        datas[6].length,
        datas[5].length,
        datas[4].length,
        datas[3].length,
        datas[2].length,
        datas[1].length,
        datas[0].length,
      ],
    },
    // {
    //   type: "bar",
    //   label: "Dataset 2",
    //   backgroundColor: `rgb(${rand()}, ${rand()}, ${rand()})`,
    //   data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
    //   borderColor: "white",
    //   borderWidth: 2,
    // },
    // {
    //   type: "bar",
    //   label: "Dataset 3",
    //   backgroundColor: `rgb(${rand()}, ${rand()}, ${rand()})`,
    //   data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
    // },
  ],
});

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};
// const data = genData();

export default function Dashbord() {
  const [data, setData] = useState({});
  const [clickedDataset, setClickedDataset] = useState('');
  const [clickedElement, setClickedElement] = useState('');
  const [clickedElements, setClickedElements] = useState('');

  useEffect(() => {
    const fetchEmployee = async () => {
      var result = await axios.get(`https://www.posb.recy-globe.com/order`);
      console.log(result);
      let todayOrders: any[] = [];
      let todayOrders1: any[] = [];
      let todayOrders2: any[] = [];
      let todayOrders3: any[] = [];
      let todayOrders4: any[] = [];
      let todayOrders5: any[] = [];
      let todayOrders6: any[] = [];
      result.data.forEach((ele: any) => {
        if (
          ele.createdAt.substring(0, 10) ===
          `${new Date().getFullYear()}-${
            new Date().getMonth() + 1 < 10
              ? '0' + (new Date().getMonth() + 1)
              : new Date().getMonth() + 1
          }-${new Date().getDate()}`
        )
          todayOrders.push(ele);
        if (
          ele.createdAt.substring(0, 10) ===
          `${new Date().getFullYear()}-${
            new Date().getMonth() + 1 < 10
              ? '0' +
                (new Date(
                  new Date().getTime() + -1 * 24 * 60 * 60 * 1000
                ).getMonth() +
                  1)
              : new Date(
                  new Date().getTime() + -1 * 24 * 60 * 60 * 1000
                ).getMonth() + 1
          }-${new Date(
            new Date().getTime() + -1 * 24 * 60 * 60 * 1000
          ).getDate()}`
        )
          todayOrders1.push(ele);
        if (
          ele.createdAt.substring(0, 10) ===
          `${new Date().getFullYear()}-${
            new Date().getMonth() + 1 < 10
              ? '0' +
                (new Date(
                  new Date().getTime() + -2 * 24 * 60 * 60 * 1000
                ).getMonth() +
                  1)
              : new Date(
                  new Date().getTime() + -2 * 24 * 60 * 60 * 1000
                ).getMonth() + 1
          }-${new Date(
            new Date().getTime() + -2 * 24 * 60 * 60 * 1000
          ).getDate()}`
        )
          todayOrders2.push(ele);

        if (
          ele.createdAt.substring(0, 10) ===
          `${new Date().getFullYear()}-${
            new Date().getMonth() + 1 < 10
              ? '0' +
                (new Date(
                  new Date().getTime() + -3 * 24 * 60 * 60 * 1000
                ).getMonth() +
                  1)
              : new Date(
                  new Date().getTime() + -3 * 24 * 60 * 60 * 1000
                ).getMonth() + 1
          }-${new Date(
            new Date().getTime() + -3 * 24 * 60 * 60 * 1000
          ).getDate()}`
        )
          todayOrders3.push(ele);
        if (
          ele.createdAt.substring(0, 10) ===
          `${new Date().getFullYear()}-${
            new Date().getMonth() + 1 < 10
              ? '0' +
                (new Date(
                  new Date().getTime() + -4 * 24 * 60 * 60 * 1000
                ).getMonth() +
                  1)
              : new Date(
                  new Date().getTime() + -4 * 24 * 60 * 60 * 1000
                ).getMonth() + 1
          }-${new Date(
            new Date().getTime() + -4 * 24 * 60 * 60 * 1000
          ).getDate()}`
        )
          todayOrders4.push(ele);
        if (
          ele.createdAt.substring(0, 10) ===
          `${new Date().getFullYear()}-${
            new Date().getMonth() + 1 < 10
              ? '0' +
                (new Date(
                  new Date().getTime() + -5 * 24 * 60 * 60 * 1000
                ).getMonth() +
                  1)
              : new Date(
                  new Date().getTime() + -5 * 24 * 60 * 60 * 1000
                ).getMonth() + 1
          }-${new Date(
            new Date().getTime() + -5 * 24 * 60 * 60 * 1000
          ).getDate()}`
        )
          todayOrders5.push(ele);
        if (
          ele.createdAt.substring(0, 10) ===
          `${new Date().getFullYear()}-${
            new Date().getMonth() + 1 < 10
              ? '0' +
                (new Date(
                  new Date().getTime() + -6 * 24 * 60 * 60 * 1000
                ).getMonth() +
                  1)
              : new Date(
                  new Date().getTime() + -6 * 24 * 60 * 60 * 1000
                ).getMonth() + 1
          }-${new Date(
            new Date().getTime() + -6 * 24 * 60 * 60 * 1000
          ).getDate()}`
        )
          todayOrders6.push(ele);
      });

      debugger;

      //   setData(data);
      var datas = genData([
        todayOrders,
        todayOrders1,
        todayOrders2,
        todayOrders3,
        todayOrders4,
        todayOrders5,
        todayOrders6,
      ]);

      setData(datas);
    };
    fetchEmployee();

    return () => {};
  }, []);

  const getDatasetAtEvent = (dataset: any) => {
    if (!dataset.length) return;

    const datasetIndex = dataset[0]._datasetIndex;
    // setClickedDataset(data.datasets[datasetIndex].label);
  };

  const getElementAtEvent = (element: any) => {
    if (!element.length) return;

    const { _datasetIndex: datasetIndex, _index: index } = element[0];
    // setClickedElement(
    //   `${data.labels[index]} - ${data.datasets[datasetIndex].data[index]}`
    // );
  };
  const getElementsAtEvent = (elements: any) => {
    if (!elements.length) return;

    setClickedElements(elements.length);
  };

  return (
    <>
      <div className="header">
        <h1 className="title">Chart</h1>
        <div className="links">
          <a
            className="btn btn-gh"
            href="https://github.com/reactchartjs/react-chartjs-2/blob/react16/example/src/charts/ClickEvents.js"
          >
            Github Source
          </a>
        </div>
      </div>
      <Bar
        data={data}
        options={options}
        getDatasetAtEvent={getDatasetAtEvent}
        getElementAtEvent={getElementAtEvent}
        getElementsAtEvent={getElementsAtEvent}
      />
      <div className="text-center">
        <p>{clickedElement}</p>
        <p>{clickedDataset}</p>
        <p>{clickedElements}</p>
      </div>
    </>
  );
}
