import React from "react";
// import { Paper, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import MuiTextField from "../helper/MuiTextField";
import { propertyOf } from "../utils";
import ProductPricing from "../../models/productPricing";
import { Paper, FormControlLabel, Checkbox } from "@mui/material";

interface PropsProductPricing {
  productPricing: ProductPricing;
  onChange: any;
}
export default function ProductPricingForm(props: PropsProductPricing) {
  return (
    // <Grid item md={12} sm={6}>
    <Paper variant="outlined">
      Product Pricing
      <MuiTextField
        name={propertyOf<ProductPricing>("pachat").toString()}
        value={props.productPricing?.pachat}
        error=""
        type="number"
        onChange={props.onChange}
        autoComplete="Price"
        label="Buy price"
        margin="normal"
        variant="filled"
      />
      <MuiTextField
        name={propertyOf<ProductPricing>("pvente").toString()}
        value={props.productPricing.pvente}
        error=""
        type="number"
        onChange={props.onChange}
        autoComplete="Price"
        label="Price"
        margin="normal"
        variant="filled"
      />
      <MuiTextField
        name={propertyOf<ProductPricing>("marge").toString()}
        value={props.productPricing.marge}
        error=""
        type="number"
        onChange={props.onChange}
        autoComplete="Marge"
        label="Marge"
        margin="normal"
        variant="filled"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={props.productPricing.isTaxable}
            onChange={props.onChange}
            name="Is Taxable"
            color="primary"
          />
        }
        label="is Taxable"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={props.productPricing.isDeduction}
            onChange={props.onChange}
            name="Is autaurized rebate"
            color="primary"
          />
        }
        label="authorised rebate"
      />
    </Paper>
    // </Grid>
  );
}
