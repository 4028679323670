import { TextField } from '@mui/material';
import React from 'react';

interface TextFielProps {
  error: string;
  type: string;
  name: string;
  value: string;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  autoComplete?: string;
  label: string;
  isRequired?: boolean;
  margin: 'normal' | 'none' | 'dense' | undefined;
  variant: 'standard' | 'outlined' | 'filled' | undefined;
  fullWidth?: boolean | true;
  style?: any;
}

export default function MuiTextField(props: TextFielProps) {
  return (
    <TextField
      value={props.value}
      autoComplete={props.autoComplete}
      name={props.name}
      required={props.isRequired !== undefined ? false : true}
      fullWidth={props.fullWidth ? false : true}
      label={props.label}
      onChange={props.onChange}
      onKeyDown={props.onKeyDown}
      autoFocus
      type={props.type}
      helperText={props.error}
      error={Boolean(props.error)}
      margin={props.margin}
      variant={props.variant}
      style={props.style}
      disabled={props.disabled}
    />
  );
}
