import React, { useRef } from "react";
// import { makeStyles } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@mui/styles';
import ReactToPrint from 'react-to-print';
import Order from '../../models/order';
import { jsPDF } from 'jspdf';
import { renderToString } from 'react-dom/server';

import logo1 from '../../../src/assets/images/téléchargement.png';
import {
  DialogContent,
  DialogContentText,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CardActions,
  Button,
  TableContainer,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material';
const logo = require("../../../src/assets/images/téléchargement.png");

interface PropsFacture {
  open: boolean;
  onClose: any;
  onPrint: any;
  // onOpen: any;
  orders: Order[];
  taxGst: any;
  taxPst: any;
  total: any;
}

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function Facture(props: PropsFacture) {
  const classes = useStyles();
  const componentRef = useRef();
  const DialogCard = () => (
    <DialogContent>
      <DialogContentText>
        <Card className={classes.root}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={`${logo1}`}
              title="Contemplative Reptile"
            ></CardMedia>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {getDateFacture()}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableBody>
                    {props.orders.map((order, index) => (
                      <>
                        <TableRow key={index + 10}>
                          <TableCell component="th" scope="row">
                            {order.products.name}: {order.quantities} *{" "}
                            {order.products.productPricing.pvente}
                          </TableCell>
                        </TableRow>
                        <TableRow key={index + 100}>
                          <TableCell align="right">
                            GST: {props.taxGst}
                          </TableCell>
                        </TableRow>
                        <TableRow key={index + 150}>
                          <TableCell align="right">
                            PST: {props.taxPst}
                          </TableCell>
                        </TableRow>
                        <TableRow key={index + 200}>
                          <TableCell align="right">{props.total}</TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary">
              Share
            </Button>
            <Button size="small" color="primary">
              Learn More
            </Button>
          </CardActions>
        </Card>

        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        ></Table>
      </DialogContentText>
    </DialogContent>
  );

  const getDateFacture = () => {
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const day = new Date().getDate();
    const hour = new Date().getHours();
    const seconde = new Date().getSeconds();
    const munites = new Date().getMinutes();
    const formattedDate = `${day}/${month}/${year}     ${hour}:${munites}:${seconde}`;
    console.log("DATE : ", new Date());
    return formattedDate;
  };
  const handlePrintFactureDialog = (content: string) => {
    // setOpenFactureDialog(true);

    const pdf = new jsPDF();
    // const pdf = new jsPDF("p", "mm", "a4");
    console.log(content);
    pdf.autoPrint();
    pdf.text("HELLO TEXT", 15, 15);
    pdf.html(content);
    pdf.output("dataurlnewwindow");
    // pdf.save("pdf");
    // setOpenFactureDialog(false);
  };

  const Test = () => <div> Hello Test</div>;
  return (
    <>
      <TableContainer component={Paper}>
        <Dialog
          // fullScreen={fullScreen}
          open={props.open}
          onClose={props.onClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle> La Fripperie</DialogTitle>
          <DialogCard />
          <DialogActions>
            <Button autoFocus onClick={props.onClose} color="secondary">
              Cancel
            </Button>

            <Button
              onClick={
                () =>
                  props.onPrint(
                    props.orders,
                    props.taxPst,
                    props.taxGst,
                    props.total
                  )
                // handlePrintFactureDialog(renderToString(<DialogCard />))
              }
              color="primary"
              autoFocus
            >
              Print
            </Button>
          </DialogActions>
        </Dialog>
      </TableContainer>
    </>
  );
}
