import Products from "./Products";
import Employee from "./employee";

export default class Order {
  // products: Products[] = new Array<Products>();
  products: Products = new Products();
  paymentMethod: string = "";
  quantities: string = "";
  id: string = "";
  employee: Employee = new Employee();
}
