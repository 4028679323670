interface BarcodeProps {
  value: string;
  name: string;
  price: string;
}
export default function Barcode(props: BarcodeProps) {
  return (
    <svg
      id="svgId"
      className="barcode"
      jsbarcode-format="CODE128"
      jsbarcode-value={props.value}
      jsbarcode-textmargin="0"
      jsbarcode-fontoptions="bold"
    />
  );
}
