import React from "react";
// import { Container } from "@material-ui/core";
import EmployeeForm from "./EmployeeForm";
import MuiEmployeeTable from "../../../components/helper/MuiEmployeeTable";
import useEmployee from "./useEmployee";
import { Container } from "@mui/material";

export default function Employees() {
  const {
    employee,
    employees,
    handleEmployeeChange,
    handleSubmit,
  } = useEmployee();

  return (
    <Container maxWidth={"lg"}>
      <EmployeeForm
        onChange={handleEmployeeChange}
        employee={employee}
        handleSubmit={handleSubmit}
      />
      <MuiEmployeeTable Employees={employees} />
    </Container>
  );
}
