import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

import Order from '../../models/order';
import MuiTextField from './MuiTextField';
import { makeStyles } from '@mui/styles';
import Paiement from '../paiement/Paiement';
import Facture from '../facture/Facture';
import {
  /*makeStyles,*/ TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@mui/material';

const TAX_GST = 0.05;
const TAX_PST = 0.1;

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

function ccyFormat(num: number) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty: number, unit: number) {
  return qty * unit;
}

function createRow(desc: string, qty: number, unit: number) {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price };
}

function subtotal(items: Order[]) {
  let subtotal = 0;
  items.forEach((ele) => {
    subtotal +=
      parseInt(ele.quantities) * parseInt(ele.products.productPricing.pvente);
  });
  return subtotal;
}

interface TableProps {
  datas: Order[];
  onChange: any;
  onDelete: any;
  onPaiment: any;
  openPaiementDialog: boolean;
  handleClosePaiementDialog: any;
  handleOpenPaiementDialog: any;
  openFactureDialog: boolean;
  handleCloseFactureDialog: any;
  handleOpenFactureDialog: any;
  handlePrintFactureDialog: any;
}

export default function MuiSalesTable(props: TableProps) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={5}>
              Details
            </TableCell>
            <TableCell align="right">Price</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Desc</TableCell>
            <TableCell align="left">Qty.</TableCell>
            <TableCell align="left">Unit Price</TableCell>
            <TableCell align="left">Taxe ?</TableCell>
            <TableCell align="left">Sum</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.datas.map((row, index) => (
            <TableRow style={{ height: 70 }} key={index}>
              <TableCell style={{ height: 70 }}>
                <Button
                  variant="contained"
                  onClick={() => props.onDelete(row)}
                  color="secondary"
                >
                  <DeleteSweepIcon />
                </Button>
              </TableCell>
              <TableCell style={{ height: 70 }}>{row.products.name}</TableCell>
              <TableCell align="left" style={{ width: 120, height: 70 }}>
                <MuiTextField
                  name={'quantities'}
                  value={row.quantities}
                  error=""
                  type="number"
                  fullWidth={false}
                  onChange={(event) => props.onChange(event, row)}
                  autoComplete="Quantity"
                  label="Quantity"
                  margin="normal"
                  variant="filled"
                />
              </TableCell>
              <TableCell style={{ height: 70 }} align="left">
                {row.products.productPricing.pvente}
              </TableCell>
              <TableCell style={{ height: 70 }} align="left">
                {row.products.productPricing.isTaxable ? 'Yes' : 'No'}
              </TableCell>

              <TableCell style={{ height: 70 }} align="left">
                {parseInt(row.products.productPricing.pvente) *
                  parseInt(row.quantities)}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell rowSpan={4} colSpan={4} />
            <TableCell>Subtotal</TableCell>
            <TableCell align="right">
              {ccyFormat(subtotal(props.datas))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>GST</TableCell>
            {/* <TableCell align="right">
              {`${(TAX_RATE * 100).toFixed(0)} %`}
            </TableCell> */}
            <TableCell align="right">
              {ccyFormat(subtotal(props.datas) * TAX_GST)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>PST</TableCell>
            {/* <TableCell align="right">
              {`${(TAX_RATE * 100).toFixed(0)} %`}
            </TableCell> */}
            <TableCell align="right">
              {ccyFormat(subtotal(props.datas) * TAX_PST)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Total</TableCell>
            <TableCell align="right">
              {ccyFormat(
                subtotal(props.datas) + subtotal(props.datas) * TAX_GST
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={5}></TableCell>
            <TableCell align="right">
              {/* <Button
                variant="contained"
                onClick={() => props.onPaiment}
                color="primary"
              >
                Paiement
              </Button> */}
              <Paiement
                onConfirm={props.onPaiment}
                open={props.openPaiementDialog}
                onClose={props.handleClosePaiementDialog}
                onOpen={props.handleOpenPaiementDialog}
                orders={props.datas}
                totalAmount={ccyFormat(
                  subtotal(props.datas) + subtotal(props.datas) * TAX_GST
                )}
              />
              <Facture
                open={props.openFactureDialog}
                onClose={props.handleCloseFactureDialog}
                onPrint={props.handlePrintFactureDialog}
                total={ccyFormat(
                  subtotal(props.datas) + subtotal(props.datas) * TAX_GST
                )}
                taxGst={ccyFormat(subtotal(props.datas) * TAX_GST)}
                taxPst={ccyFormat(subtotal(props.datas) * TAX_PST)}
                orders={props.datas}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
