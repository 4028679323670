import { Route, Switch, BrowserRouter } from 'react-router-dom';
import './App.css';
import Products from './views/pages/products/ProductsPage';
import { MuiDrawer } from './components/drawer';
import { orange } from '@mui/material/colors';
import { Container, createTheme, ThemeProvider } from '@mui/material';
import Sales from './views/pages/Sales/Sales';
declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

function App() {
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
    typography: {
      fontFamily:
        '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <main className="container">
        <BrowserRouter>
          <Switch>
            <MuiDrawer />
            <Route path="/products" component={Products}></Route>
            <Route path="/sales" component={Sales}></Route>
            <Route path="/" component={App}></Route>
          </Switch>
        </BrowserRouter>
      </main>
    </ThemeProvider>
  );
}

export default App;
