import { useState, useEffect } from "react";
import Products from "../../../models/Products";
import Category from "../../../models/category";
import Marque from "../../../models/marque";
import ProductIdent from "../../../models/productIdent";
import ProductPricing from "../../../models/productPricing";
import axios from "axios";
import Handler from "./handler";

const useProducts = (): [
  Products[],
  Products,
  Category,
  Marque,
  Category[],
  Marque[],
  ProductIdent,
  ProductPricing,
  {
    value: string;
    label: string;
  }[],
  //   any,
  //   any,
  //   any,
  Handler[],
  any,
  //   any,
  //   any,
  //   any,
  //   any,
  any,
  //   any,
  //   any,
  //   any,
  any
] => {
  const [products, setProducts] = useState(new Array<Products>());
  const [currentProduct, setCurrentProduct] = useState(new Products());
  const [category, setCategory] = useState(new Category());
  const [marque, setMarque] = useState(new Marque());
  const [categories, setCategories] = useState(new Array<Category>());
  const [marques, setMarques] = useState(new Array<Category>());
  const [productIdent, setProductIdent] = useState(new ProductIdent());
  const [productPricing, setProductPricing] = useState(new ProductPricing());
  const [columns, setColumns] = useState([
    { value: "id", label: "Id" },
    // { value: "categorie", label: "Categorie" },
    { value: "quantities", label: "Quantities" },
    { value: "price", label: "Price" },
    { value: "name", label: "Name" },
    { value: "handlers", label: "Handlers" },
  ]);

  const handleUpdate = (product: Products) => {
    debugger;
    let prodIdent = new ProductIdent();
    prodIdent = product.identifications;
    setProductIdent(product.identifications);
    setProductPricing(product.productPricing);
    setCategory(product.category);
    setMarque(product.marque);
    console.log("update", product);
  };

  const handleDelete = (product: Products) => {
    console.log("Delete", product);
  };

  const handleView = (product: Products) => {
    console.log("View", product);
  };

  const [handlers, setHandlers] = useState([
    new Handler(handleUpdate, "Update", "contained", "primary", 2),
    new Handler(handleView, "View", "contained", "primary", 2),
    new Handler(handleDelete, "Delete", "contained", "secondary", 2),
  ]);

  //   const [handlers, setHandlers] = useState([
  //     {
  //       method: handleUpdate,
  //       label: "Update",
  //       variant: "contained",
  //       color: "primary",
  //       spacing: 2,
  //     },
  //     {
  //       method: handleView,
  //       label: "View",
  //       className: "contained",
  //       color: "primary",
  //       spacing: 2,
  //     },
  //     {
  //       method: handleDelete,
  //       label: "Delete",
  //       className: "contained",
  //       color: "secondary",
  //       spacing: 2,
  //     },
  //   ]);

  const handleClick = (
    event: React.MouseEvent<unknown>,
    name: string,
    selected: string[]
  ) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
  };

  useEffect(() => {
    // get categories tt
    const fetchProducts = async () => {
      var result = await axios.get(`https://www.posb.recy-globe.com/products`);
      let prods = new Array<Products>();

      result.data.forEach((ele: any) => {
        let prod = new Products();

        prod.identifications.id = ele.id;
        prod.identifications.name = ele.name;
        prod.identifications.manufacturerIdent = ele.manufactureident;
        prod.identifications.systemIdent = ele.systemident;

        prod.productPricing.isDeduction = ele.reduction;
        prod.productPricing.isTaxable = ele.taxable;
        prod.productPricing.majoration = ele.marge;
        prod.productPricing.marge = ele.marge;
        prod.productPricing.pachat = ele.pachat;
        prod.productPricing.pvente = ele.pvente;

        prod.marque = ele.marque;

        prod.category = ele.category;

        prod.fournisseur = ele.fournisseur;

        prod.quantities = ele.stock;
        prod.name = ele.name;
        prod.price = ele.pvente;
        prod.id = ele.id;

        prods.push(prod);
      });
      setProducts(prods);
      console.log('prods: ', prods);
      console.log(result);
    };

    fetchProducts();

    // get categories
    const fetchCategorie = async () => {
      var result = await axios.get(`https://www.posb.recy-globe.com/category`);
      setCategories(result.data);
      console.log(result);
    };
    fetchCategorie();
    // get marque
    const fetchMarque = async () => {
      var result = await axios.get(`https://www.posb.recy-globe.com/marque`);
      setMarques(result.data);
      console.log(result);
    };
    fetchMarque();

    // return () => {
    //   cleanup
    // }
  }, []);

  const handleProductIdentChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log(event.target.value);
    debugger;
    setProductIdent((productIdent) => ({
      ...productIdent,
      [event.target.name]: event.target.value,
    }));
    console.log('productIdent', productIdent);
  };

  const handleProductPricingChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProductPricing((productPricing) => ({
      ...productPricing,
      [event.target.name]: event.target.value,
    }));
    console.log('productPricing', productPricing);
  };

  const handleCategoryChange = async (
    event: any,
    value: Category,
    reason: string
  ) => {
    console.log('handleCategoryChange', event.target.value);
    debugger;
    let newCategory = new Category();
    if (value == null && event.code === 'Enter') {
      newCategory.name = event.target.value;
      await axios.post(`https://www.posb.recy-globe.com/category`, newCategory);

      var result = await axios.get(`https://www.posb.recy-globe.com/category`);
      setCategories(result.data);
      let selectedCategory = result.data.find(
        (ele: { name: string }) => ele.name === event.target.value
      );
      setCategory(selectedCategory);
    } else if (value !== undefined) setCategory(value);
    // setCategory((category) => ({
    //   ...category,
    //   [event.target.name]: event.target.value,
    // }));
  };

  const handleMarqueChange = async (
    event: any,
    value: Marque,
    reason: string
  ) => {
    debugger;
    console.log(event.target.value);
    let newMarque = new Marque();
    if (value == null && event.code === 'Enter') {
      newMarque.name = event.target.value;
      await axios.post(`https://www.posb.recy-globe.com/marque`, newMarque);

      var result = await axios.get(`https://www.posb.recy-globe.com/marque`);
      setMarques(result.data);
      let selectedMarque = result.data.find(
        (ele: { name: string }) => ele.name === event.target.value
      );
      setMarque(selectedMarque);
    } else if (value !== undefined) setMarque(value);
    // setMarque((marque) => ({
    //   ...marque,
    //   [event.target.name]: event.target.value,
    // }));
  };

  const changesEvents = {
    onProductIdentChange: handleProductIdentChange,
    onProductPricingChange: handleProductPricingChange,
    onMarqueChange: handleMarqueChange,
    onCategoryChange: handleCategoryChange,
  };

  const handleSave = async () => {
    var product = new Products();
    product.identifications = productIdent;
    product.productPricing = productPricing;
    product.category = category;
    product.quantities = '10';
    product.name = 'name';
    product.marque = marque;

    setCurrentProduct(product);
    console.log('console: ', product);
    var result = await axios.post(
      `https://www.posb.recy-globe.com/products`,
      product
    );
    console.log(result);
  };

  const handleSaveCategory = async () => {
    var result = await axios.post(
      `https://www.posb.recy-globe.com/category`,
      category
    );
    console.log(result);
  };

  const handleSaveMarque = async () => {
    var result = await axios.post(
      `https://www.posb.recy-globe.com/marque`,
      marque
    );
    console.log(result);
  };

  const saveEvents = {
    onSaveCategory: handleSaveCategory,
    onSaveMarque: handleSaveMarque,
    onSaveProduct: handleSave,
  };

  return [
    products,
    currentProduct,
    category,
    marque,
    categories,
    marques,
    productIdent,
    productPricing,
    columns,
    // handleUpdate,
    // handleDelete,
    // handleView,
    handlers,
    handleClick,
    // handleProductIdentChange,
    // handleProductPricingChange,
    // handleCategoryChange,
    // handleMarqueChange,
    changesEvents,
    // handleSave,
    // handleSaveCategory,
    // handleSaveMarque,
    saveEvents,
  ];
};

export default useProducts;
