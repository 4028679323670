import React, { useContext } from "react";

import useSales from "./useSales";
import MuiTextField from "../../../components/helper/MuiTextField";
import { propertyOf } from "../../../components/utils";
import ProductIdent from "../../../models/productIdent";
import MuiSalesTable from "../../../components/helper/MuiSalesTable";
import MuiSimpleSelect from "../../../components/helper/MuiSimpleSelect";
import CashRegisterContext from "../../../components/context/CashRegisterContext";
import { Container, Button, Grid } from "@mui/material";

export default function Sales() {
  const {
    productName,
    products,
    employees,
    selectedEmployee,
    orders,
    openPaiementDialog,
    openFactureDialog,

    // quantities,
    columns,
    isHidden,
    onChangeQuantities,
    handleSeacrh,
    handleChange,
    handleKeyDown,
    handleClick,
    handleRemove,
    handlers,
    handlePaiement,
    handleClosePaiementDialog,
    handleOpenPaiementDialog,
    handleFacture,
    handleCloseFactureDialog,
    handleOpenFactureDialog,
    handlePrintFactureDialog,
    handleSelectEmployee,
  } = useSales();
  const Handle = () => {};
  const context = useContext(CashRegisterContext);
  debugger;
  return (
    // <CashRegisterContext.Provider
    //   value={{
    //     currentEmployee: context.currentEmployee,
    //     handleShift: Handle,
    //     setEmployee: Handle,
    //   }}
    // >
    <Container maxWidth="lg">
      <MuiSimpleSelect
        data={context.currentEmployee}
        handleSelectChange={handleSelectEmployee}
        datas={employees}
      />
      <Button
        hidden={!isHidden}
        variant="contained"
        onClick={() => handleSeacrh(productName)}
        color="primary"
      >
        Open
      </Button>
      <Button
        hidden={isHidden}
        variant="contained"
        onClick={() => handleSeacrh(productName)}
        color="primary"
      >
        Close
      </Button>
      <div hidden={isHidden}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <MuiTextField
              name={propertyOf<ProductIdent>("name").toString()}
              value={productName}
              error=""
              type="text"
              onChange={handleChange}
              // onKeyDown={handleKeyDown}
              autoComplete="Name"
              label="Name"
              margin="normal"
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Button
              variant="contained"
              onClick={() => handleSeacrh(productName)}
              color="primary"
            >
              Search
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <MuiSalesTable
            datas={orders}
            onChange={onChangeQuantities}
            onDelete={handleRemove}
            onPaiment={handlePaiement}
            openPaiementDialog={openPaiementDialog}
            handleClosePaiementDialog={handleClosePaiementDialog}
            handleOpenPaiementDialog={handleOpenPaiementDialog}
            openFactureDialog={openFactureDialog}
            handleCloseFactureDialog={handleCloseFactureDialog}
            handleOpenFactureDialog={handleOpenFactureDialog}
            handlePrintFactureDialog={handlePrintFactureDialog}
          />
        </Grid>
      </div>
    </Container>
    // </CashRegisterContext.Provider>
  );
}
