import { useState, useEffect, useContext } from "react";
import { jsPDF } from "jspdf";

import Products from "../../../models/Products";
import Order from "../../../models/order";
import logo from "../../../assets/images/logo.jpg";
import Employee from "../../../models/employee";
import CashRegisterContext from "../../../components/context/CashRegisterContext";
import axios from "axios";

const useSales = () => {
  const [products, setProducts] = useState(new Array<Products>());
  const [employees, setEmployees] = useState(new Array<Employee>());
  const [orders, setOrders] = useState(new Array<Order>());
  const [selectedEmployee, setSelectedEmployee] = useState(new Employee());
  const [isHidden, setIsHidden] = useState(true);
  const [productName, setProductName] = useState("");
  // const [quantities, setQuantities] = useState("1");
  const [openPaiementDialog, setOpenPaiementDialog] = useState(false);
  const [openFactureDialog, setOpenFactureDialog] = useState(false);
  const [columns, setColumns] = useState([
    { value: "id", label: "Id" },
    // { value: "categorie", label: "Categorie" },
    { value: "quantities", label: "Quantities" },
    { value: "price", label: "Price" },
    { value: "name", label: "Name" },
    { value: "handlers", label: "Handlers" },
  ]);
  const context = useContext(CashRegisterContext);

  useEffect(() => {
    const fetchEmployee = async () => {
      var result = await axios.get(`https://www.posb.recy-globe.com/employee`);
      setEmployees(result.data);
    };
    fetchEmployee();
    //
    setSelectedEmployee(context.currentEmployee);
    return () => {};
  }, []);

  const handleClick = () => {};
  const handleSelectEmployee = (event: React.ChangeEvent<any>) => {
    console.log('event: ', event);
    setSelectedEmployee(event.target.value);
    context.setEmployee(event.target.value);
    context.currentEmployee = event.target.value;
    let ords = new Array<Order>();
    orders.forEach((val) => ords.push(Object.assign({}, val)));
    ords.forEach((ele) => {
      ele.employee = selectedEmployee;
    });
    setOrders(ords);
    setIsHidden(false);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length == 9) {
      debugger;
    }
    setProductName(event.currentTarget.value);
    console.log('Pro: ', event.currentTarget.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    debugger;
    // setProductName(event.currentTarget.value);
    // console.log("Pro: ", event.currentTarget.value);
    console.log('Event.key: ', event.key);
  };

  const onChangeQuantities = (
    event: React.ChangeEvent<HTMLInputElement>,
    currentOrder: Order
  ) => {
    var qty = event.currentTarget.value;

    let ords = new Array<Order>();
    orders.forEach((val) => ords.push(Object.assign({}, val)));

    ords.forEach((ele) => {
      if (ele.products.name == currentOrder.products.name)
        ele.quantities = event.currentTarget.value;
      ele.employee = selectedEmployee;
    });
    setOrders(ords);
  };

  const handleSeacrh = async (productName: string) => {
    debugger;
    if (productName !== '') {
      console.log('Search: ', productName);
      var result = await axios.get(
        `https://www.posb.recy-globe.com/products/${productName}`
      );
      if (result.data != null) {
        console.log('Search: ', result);

        let prod = mapProduct(result);
        let existOrder = orders.find(
          (ele) => ele.products?.identifications.systemIdent == productName
        );

        if (existOrder != undefined) {
          let qty = parseInt(existOrder.quantities);

          let ords = new Array<Order>();
          orders.forEach((val) => ords.push(Object.assign({}, val)));
          ords.forEach((ele) => {
            if (ele.products?.identifications.systemIdent === productName) {
              qty += 1;
              ele.quantities = qty.toString();
            }
            ele.employee = selectedEmployee;
          });
          setOrders(ords);
        } else {
          let newOrder = new Order();
          newOrder.products = prod;
          newOrder.quantities = '1';
          newOrder.employee = selectedEmployee;
          setOrders([...orders, newOrder]);
        }
      }
    }

    // else setProducts([...products, prod]);
  };
  const handleRemove = (row: Order) => {
    var ords = orders.filter((ele) => ele.products.name != row.products.name);
    setOrders(ords);
  };

  const handlePaiement = async (orders: Order[]) => {
    // save the sales
    console.log('orders : ', orders);
    var result = await axios.post(
      `https://www.posb.recy-globe.com/order`,
      orders
    );
    setOpenPaiementDialog(false);
    handlePrintFactureDialog(orders, '0', '0', '10');
    setOrders(new Array<Order>());
    setProductName('');
    // setOpenFactureDialog(true);
  };

  const handleClosePaiementDialog = () => {
    setOpenPaiementDialog(false);
  };

  const handleOpenPaiementDialog = () => {
    setOpenPaiementDialog(true);
  };

  const handleFacture = () => {
    setOpenFactureDialog(true);
  };

  function createHeaders(keys: any[]) {
    var result = [];
    for (var i = 0; i < keys.length; i += 1) {
      result.push({
        id: keys[i],
        name: keys[i],
        prompt: keys[i],
        width: 65,
        align: "center",
        padding: 0,
      });
    }
    return result;
  }

  const afterPrint = () => {
    console.log("After print");
    window.close();
  };
  const handlePrintFactureDialog = (
    orders: Order[],
    pst: string,
    gst: string,
    total: string
  ) => {
    setOpenFactureDialog(true);

    const pdf = new jsPDF();
    pdf.addFont("Lato-Regular", "Lato-Regular", "normal");
    // pdf.setFont("Comic Sans");
    pdf.setFont("Lato-Regular");
    // pdf.setFontSize(2);
    // const pdf = new jsPDF("p", "mm", "a4");
    console.log(orders);
    pdf.addImage(logo, "PNG", 20, 10, 50, 30);
    let yIndice = 40;
    pdf.setTextColor(100);
    pdf.setFontSize(10);
    pdf.text(`Merci d'encourager les`, 20, yIndice + 10);
    pdf.setFontSize(10);
    pdf.text(`entreprise adaptee`, 20, yIndice + 20);
    pdf.setFontSize(10);
    pdf.text(`pour l'intégration de`, 20, yIndice + 30);
    pdf.text(`personnes handicapees (130 emplois)`, 20, yIndice + 40);
    pdf.text(`Sales Receipt`, 20, yIndice + 50);
    pdf.text(new Date().toString().substring(0, 24), 20, yIndice + 60);
    yIndice = 120;
    orders.forEach((element) => {
      pdf.text(element.products.name + " * ", 20, yIndice);
      pdf.text(element.quantities, 40, yIndice);
      pdf.text(element.products.productPricing.pvente + "", 50, yIndice);
      yIndice += 5;
    });

    pdf.text(`PST : ${pst}`, 40, yIndice);
    pdf.text(`GST : ${gst}`, 40, yIndice + 5);
    pdf.text(`Total : ${total}`, 35, yIndice + 10);
    pdf.text(`Paymnet with: ${orders[0].paymentMethod}`, 20, yIndice + 20);
    pdf.text(`Vente finale`, 20, yIndice + 30);
    pdf.text(`Aucun échange`, 20, yIndice + 40);
    pdf.text(`Aucun remboursement`, 20, yIndice + 50);
    pdf.setFontSize(14);
    pdf.text(`No tax applied`, 20, yIndice + 60);

    pdf.autoPrint();
    // pdf.html(content);
    // pdf.output("datauri");
    pdf.output("dataurlnewwindow");
    window.onafterprint = afterPrint;
    // pdf.save("pdf");

    // console.log("ORDERS: ", orders);
    // var doc = new jsPDF();
    // doc.addImage(logo, "PNG", 0, 0, 100, 50);
    // doc.autoPrint();
    // // doc.output("datauristring"); //returns the data uri string
    // console.log("PRINT: ");
    // doc.output("datauri");

    // doc.output("dataurlnewwindow");
    setOpenFactureDialog(false);
  };

  const handleCloseFactureDialog = () => {
    setOpenFactureDialog(false);
  };

  const handleOpenFactureDialog = () => {
    setOpenFactureDialog(true);
  };
  const handlers = () => {};

  return {
    productName,
    products,
    employees,
    selectedEmployee,
    orders,
    openPaiementDialog,
    openFactureDialog,
    // quantities,
    columns,
    isHidden,
    onChangeQuantities,
    handleSeacrh,
    handleChange,
    handleKeyDown,
    handleClick,
    handleRemove,
    handlers,
    handlePaiement,
    handleClosePaiementDialog,
    handleOpenPaiementDialog,
    handleFacture,
    handleCloseFactureDialog,
    handleOpenFactureDialog,
    handlePrintFactureDialog,
    handleSelectEmployee,
  };
};

export default useSales;
function mapProduct(result: any) {
  let prod = new Products();
  if (result.data) {
    prod.identifications.id = result.data.id;
    prod.identifications.name = result.data.name;
    prod.identifications.manufacturerIdent = result.data.manufactureident;
    prod.identifications.systemIdent = result.data.systemident;
    prod.productPricing.isDeduction = result.data.reduction;
    prod.productPricing.isTaxable = result.data.taxable;
    prod.productPricing.majoration = result.data.marge;
    prod.productPricing.marge = result.data.marge;
    prod.productPricing.pachat = result.data.pachat;
    prod.productPricing.pvente = result.data.pvente;
    prod.marque = result.data.marque;
    prod.category = result.data.category;
    prod.fournisseur = result.data.fournisseur;
    prod.quantities = result.data.stock;
    prod.name = result.data.name;
    prod.price = result.data.pvente;
    prod.id = result.data.id;
  }
  return prod;
}
