import Category from "../../models/category";
import CategoryForm from "../category/CategoryForm";
import MarqueForm from "../marque/MarqueForm";
import Marque from "../../models/marque";
import { Grid } from "@mui/material";

interface PropsOrganiser {
  categories: Category[];
  category: Category;
  marque: Marque;
  marques: Marque[];
  onChange: any;
  onSave: any;
}
export default function OrganiserForm(props: PropsOrganiser) {
  return (
    <Grid container spacing={3}>
      {/* <Paper > */}
      <Grid item xs={12} sm={6} md={6}>
        <CategoryForm
          onSave={props.onSave.onSaveCategory}
          onChange={props.onChange.onCategoryChange}
          categories={props.categories}
          category={props.category}
        />
      </Grid>
      {/* </Paper> */}

      {/* <Paper> */}
      <Grid item xs={12} sm={6} md={6}>
        <MarqueForm
          onSave={props.onSave.onSaveMarque}
          onChange={props.onChange.onMarqueChange}
          marques={props.marques}
          marque={props.marque}
        />
      </Grid>
      {/* </Paper> */}
    </Grid>
  );
}
