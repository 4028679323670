import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: 400,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

interface MuiSelectProps {
  datas: Array<any>;
  data: any;
  handleSelectChange: any;
}

export default function MuiSimpleSelect(props: MuiSelectProps) {
  const classes = useStyles();

  return (
    <>
      <FormControl variant="outlined" /* className={classes.formControl}*/>
        <InputLabel id="demo-simple-select-outlined-label">
          Select employee to start your sales
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={props.data}
          onChange={props.handleSelectChange}
          label="Select employee to start your sales"
        >
          {props.datas.map((ele) => (
            <MenuItem value={ele}>{ele.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
